import React, { useContext, useEffect, useRef } from "react";

import * as Styled from "./styled";
import useMediaSession from ".state/hooks/useMediaSession";
import { VideoContext } from ".state/context/VideoManager";
import { album, artist, cover, title, video } from ".data/home/intro";

/**
 * Video
 */
const Video: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { toggleMuted, registerVideo } = useContext(VideoContext);

  useMediaSession({
    album,
    artist,
    cover,
    title,
    onPause: toggleMuted,
  });

  useEffect(() => {
    registerVideo(videoRef.current);
  }, []);

  return (
    <Styled.Video
      autoPlay
      disablePictureInPicture
      loop
      muted
      playsInline
      // poster={cover}
      ref={videoRef}
      src={video}
      typeof="video/mp4"
    />
  );
};

export default Video;
