import { useEffect } from "react";

export interface IMediaSession {
  album?: string;
  artist?: string;
  cover?: string;
  title?: string;
  onPlay?: () => void;
  onPause?: () => void;
}

/**
 * useMediaSession
 */
const useMediaSession = ({
  album = "album",
  artist = "artist",
  cover = "cover",
  title = "title",
  onPlay = () => console.log("play"),
  onPause = () => console.log("pause"),
}: IMediaSession) => {
  const artwork = [
    {
      src: cover,
    },
  ];
  const { mediaSession } = navigator;

  useEffect(() => {
    mediaSession.metadata = new MediaMetadata({
      album,
      artist,
      artwork,
      title,
    });
    // return () => {
    //   mediaSession.metadata = null;
    // };
  }, [album, artist, artwork, title]);

  useEffect(() => {
    mediaSession.setActionHandler("play", onPlay);
    return () => {
      mediaSession.setActionHandler("play", null);
    };
  }, [onPlay]);

  useEffect(() => {
    mediaSession.setActionHandler("pause", onPause);
    return () => {
      mediaSession.setActionHandler("pause", null);
    };
  }, [onPause]);

  return mediaSession;
};

export default useMediaSession;
