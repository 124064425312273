import React from "react";

import * as Styled from "./styled";
import Container from ".components/styles/Container";
import Socials from "./Socials";
import ThemeToggler from ".components/common/ThemeToggler";

/**
 * Footer
 */
const Footer: React.FC = () => (
  <Styled.Background>
    <Styled.Container as={Container}>
      <Styled.Details>
        <ThemeToggler />
        <h3>
          Rambler<sup>5</sup>
        </h3>
        <p>© RamblerFive {new Date().getFullYear()}</p>
      </Styled.Details>
      <Socials />
    </Styled.Container>
  </Styled.Background>
);

export default Footer;
