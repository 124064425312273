import styled from "styled-components";

import waves from ".assets/svgs/waves.svg";

export const Background = styled.div`
  background-image: url(${waves});
  background-size: cover;
  margin-top: 2rem;
  padding: 22rem 0 2rem 0;
`;

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;
