import React from "react";

import * as Styled from "./styled";
import Section from ".components/styles/Section";

/**
 * Contact
 */
const Contact: React.FC = () => {
  return (
    <Section id="contact">
      <Styled.Heading>Contact</Styled.Heading>
      <Styled.Container>
        <p>ramblerfive@web.de</p>
      </Styled.Container>
    </Section>
  );
};

export default Contact;
