import React from "react";

import * as Styled from "./styled";
import routes from "./routes";

/**
 * Menu
 */
const Menu: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Links>
        {routes.map((r, i) => (
          <Styled.Link key={i} to={r.route}>
            {r.name}
          </Styled.Link>
        ))}
      </Styled.Links>
      <Styled.Bars />
    </Styled.Container>
  );
};

export default Menu;
