import React from "react";

import * as Styled from "./styled";
import Section from ".components/styles/Section";

/**
 * About
 */
const About: React.FC = () => {
  return (
    <Section id="about">
      <Styled.Heading>About Us</Styled.Heading>
      <Styled.Container>
        <p>
          RamblerFive ist eine Band aus Regensburg. Sie gönnt sich den Luxus
          genau die Musik zu machen, die den Musikern schon immer gefällt.
          Selbsterdachtes und Selbstgemachtes. Keine Coverband. Grooviger
          Gitarrenrock, kantige Bluessounds, Ausflüge in den Folkrock oder in
          Rock-Gefilde der härteren Gangart. Nicht festgelegt auf eine
          Stilrichtung: Lebendig, frei und kreativ. Handgemacht, bunt,
          ungeschliffen und mit Texten die auch Themen haben. Songs über das
          Leben, auch das der fünf älteren Jungs, die auf der Bühne stehen: Vom
          Arbeiten, Lieben, Älterwerden. Vom Leben, Funktionieren und dem Traum,
          sein eigenes Ding zu machen. BluesFolkFunkPopRock mit Herz und Hand
          und viel Spaß am Improvisieren.
        </p>
      </Styled.Container>
    </Section>
  );
};

export default About;
