import Img01 from ".assets/images/gallery/1.jpg";
import Img02 from ".assets/images/gallery/2.jpg";
import Img03 from ".assets/images/gallery/3.jpg";
import Img04 from ".assets/images/gallery/4.jpg";
import Img05 from ".assets/images/gallery/5.jpg";
import Img06 from ".assets/images/gallery/6.jpg";
import Img07 from ".assets/images/gallery/7.jpg";
import Img08 from ".assets/images/gallery/8.jpg";
import Img09 from ".assets/images/gallery/9.jpg";
import Img10 from ".assets/images/gallery/10.jpg";
import Img11 from ".assets/images/gallery/11.jpg";
import Img12 from ".assets/images/gallery/12.jpg";
import Img13 from ".assets/images/gallery/13.jpg";

export default [
  { name: "Image01", image: Img01 },
  { name: "Image02", image: Img02 },
  { name: "Image03", image: Img03 },
  { name: "Image04", image: Img04 },
  { name: "Image05", image: Img05 },
  { name: "Image06", image: Img06 },
  { name: "Image07", image: Img07 },
  { name: "Image08", image: Img08 },
  { name: "Image09", image: Img09 },
  { name: "Image10", image: Img10 },
  { name: "Image11", image: Img11 },
  { name: "Image12", image: Img12 },
  { name: "Image13", image: Img13 },
];
