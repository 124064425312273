import React from "react";

import * as Styled from "./styled";
import Logo from "./Logo";
import Menu from "./Menu";
import VideoMuter from ".components/common/VideoMuter";

/**
 * Header
 */
const Header: React.FC = () => {
  return (
    <Styled.Navbar>
      <Styled.LogoContainer>
        <Logo />
        <Styled.MuterWrapper>
          <VideoMuter />
        </Styled.MuterWrapper>
      </Styled.LogoContainer>
      <Menu />
    </Styled.Navbar>
  );
};

export default Header;
