import coverSrc from ".assets/images/intro.png";
import videoSrc from ".assets/videos/intro.mp4";

/**
 * Intro data
 */
export const album = "Live im Cowboy-Club";
export const artist = "RamblerFive";
export const cover = coverSrc;
export const title = "Time slips through my hands";
export const video = videoSrc;
