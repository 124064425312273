import React from "react";

import * as Styled from "./styled";
import { links } from ".data/meta";

/**
 * Socials
 */
const Socials: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Link
        aria-label={`Facebook link`}
        href={links.facebook}
        key={0}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Styled.Facebook />
      </Styled.Link>
      <Styled.Link
        aria-label={`Youtube link`}
        href={links.youtube}
        key={1}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Styled.Youtube />
      </Styled.Link>
      <Styled.Link
        aria-label={`Soundcloud link`}
        href={links.soundcloud}
        key={2}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Styled.SoundCloud />
      </Styled.Link>
      <Styled.Link
        aria-label={`Github link`}
        href={links.github}
        key={3}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Styled.Github />
      </Styled.Link>
    </Styled.Container>
  );
};

export default Socials;
