import React from "react";

import About from ".components/home/About";
import Band from ".components/home/Band";
import Contact from ".components/home/Contact";
import Events from ".components/home/Events";
import Gallery from ".components/home/Gallery";
import Header from ".components/core/Header";
import Intro from ".components/home/Intro";
import Layout from ".components/core/Layout";
import VideoManager from ".state/context/VideoManager";

/**
 * Homepage
 */
export default function Home() {
  return (
    <Layout>
      <VideoManager>
        <Header />
        <Intro />
        <About />
        <Band />
        <Gallery />
        <Events />
        <Contact />
      </VideoManager>
    </Layout>
  );
}
