import styled from "styled-components";
import {
  ImFacebook2,
  ImGithub,
  ImSoundcloud2,
  ImYoutube,
} from "react-icons/im";

import devices from ".config/devices";

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

export const Link = styled.a`
  font-size: 2.5rem;
  margin: 0.1rem 0.5rem 0 0;
`;

export const Facebook = styled(ImFacebook2)``;

export const Youtube = styled(ImYoutube)``;

export const SoundCloud = styled(ImSoundcloud2)``;

export const Github = styled(ImGithub)``;
