import styled from "styled-components";

import devices from ".config/devices";

export const Background = styled.div<{ image: string }>`
  align-items: flex-end;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  width: 100vw;

  :before {
    background-color: ${({ theme }) => theme.overlay};
    bottom: 0;
    content: "";
    height: inherit;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    width: inherit;
    z-index: 20;
  }
`;

export const VideoWrapper = styled.div`
  height: inherit;
  position: absolute;
  width: inherit;
  z-index: 10;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  justify-content: flex-end;
  padding: 5%;
  width: 100%;
  z-index: 30;
`;

export const TitleWrapper = styled.div`
  padding: 10vh 5vw;

  @media ${devices.laptop} {
    align-self: flex-start;
  }
`;
