import React from "react";

import * as Styled from "./styled";
import Fred from ".assets/images/band/fred.jpg";
import Mario from ".assets/images/band/mario.jpg";
import Ralf from ".assets/images/band/ralf.jpg";
import Roland from ".assets/images/band/roland.jpg";
import Section from ".components/styles/Section";
import Tom from ".assets/images/band/tom.jpg";

/**
 * Band
 */
const Band: React.FC = () => {
  return (
    <Section id="band">
      <Styled.Heading>The Band</Styled.Heading>
      <Styled.Container>
        <p>Fred Lang: &quot;The Machine&quot; - Drums und Percussions</p>
        <img
          alt="Fred"
          src={Fred}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
        <p>Mario Koller: &quot;The Groove&quot; - Bass, Vocals, Didgeridoo</p>
        <img
          alt="Mario"
          src={Mario}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
        <p>
          Ralf Kapfberger: &quot;The Heart&quot; - Songwriter, Guitars, Vocals,
          Harps
        </p>
        <img
          alt="Ralf"
          src={Ralf}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
        <p>Roland Bauch: &quot;The Soul&quot; - Guitars, Vocals</p>
        <img
          alt="Roland"
          src={Roland}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
        <p>Tom Aumeier: &quot;The Sound&quot; - Keyboards, Production</p>
        <img
          alt="Tom"
          src={Tom}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
      </Styled.Container>
    </Section>
  );
};

export default Band;
