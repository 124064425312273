import React, { useContext } from "react";

import * as Styled from "./styled";
import { VideoContext } from ".state/context/VideoManager";

/**
 * VideoMuter
 */
const VideoMuter: React.FC = () => {
  const { muted, toggleMuted } = useContext(VideoContext);

  return (
    <Styled.Wrapper onClick={toggleMuted}>
      {muted ? <Styled.Unmute /> : <Styled.Mute />}
    </Styled.Wrapper>
  );
};

export default VideoMuter;
