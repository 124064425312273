import React from "react";

import * as Styled from "./styled";
import Section from ".components/styles/Section";
import images from "./images";

/**
 * Gallery
 */
const Gallery: React.FC = () => {
  return (
    <Section id="gallery">
      <Styled.Heading>Gallery</Styled.Heading>
      <Styled.Container>
        {images.map((e, i) => (
          <img
            key={i}
            alt={e.name}
            src={e.image}
            style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
          />
        ))}
      </Styled.Container>
    </Section>
  );
};

export default Gallery;
