import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Background = styled.div`
  opacity: 0.7;
  position: absolute;
  width: clamp(17rem, calc(5rem + 30vmin), 32rem);

  svg {
    fill: ${({ theme }) => theme.background};
  }
`;

export const Text = styled.div`
  text-align: end;
  margin-bottom: 3rem;
  user-select: none;
  z-index: 1;
`;
