import styled from "styled-components";
import { GoMute, GoUnmute } from "react-icons/go";

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.button};
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  padding: 0.8rem;

  :hover {
    background-color: ${({ theme }) => theme.button_hover};
  }
`;

export const Mute = styled(GoMute)``;

export const Unmute = styled(GoUnmute)``;
