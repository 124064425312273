import styled from "styled-components";

export const Navbar = styled.nav`
  background-color: ${({ theme }) => theme.header};
  box-sizing: border-box;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 0.8rem;
  position: fixed;
  width: 100%;
  z-index: 999;
`;

export const LogoContainer = styled.div`
  display: flex;
  height: inherit;
`;

export const MuterWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-left: 1.6rem;
`;
