import React from "react";

import Footer from "./Footer";
import Global from ".components/styles/Global";

/**
 * Layout
 */
const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Global />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
