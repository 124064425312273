import React from "react";

import * as Styled from "./styled";
import Cowboyclub from ".assets/images/events/2022_cowboyclub.png";
import Deuerling from ".assets/images/events/2022_deuerling.jpg";
import Gassenfest from ".assets/images/events/2022_gassenfest.png";
import Section from ".components/styles/Section";

/**
 * Events
 */
const Events: React.FC = () => {
  return (
    <Section id="events">
      <Styled.Heading>Events</Styled.Heading>
      <Styled.Container>
        <img
          alt="Gassenfest"
          src={Gassenfest}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
        <img
          alt="Cowboyclub"
          src={Cowboyclub}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
        <img
          alt="Deuerling"
          src={Deuerling}
          style={{ margin: "1rem", maxWidth: "100%", width: "70rem" }}
        />
      </Styled.Container>
    </Section>
  );
};

export default Events;
