import React, { createContext } from "react";

import useVideoMuted from ".state/hooks/useVideoMuted";

export const VideoContext = createContext(
  {} as {
    muted: boolean;
    toggleMuted: () => void;
    registerVideo: (current: HTMLVideoElement | null) => void;
  }
);

/**
 * VideoManager
 */
const VideoManager: React.FC = ({ children }) => {
  const [muted, toggleMuted, registerVideo] = useVideoMuted();

  return (
    <VideoContext.Provider value={{ muted, toggleMuted, registerVideo }}>
      {children}
    </VideoContext.Provider>
  );
};

export default VideoManager;
