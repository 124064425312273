import styled from "styled-components";

export const Switch = styled.label`
  background-color: ${({ theme }) => theme.tt_switch};
  border-radius: 34px;
  cursor: pointer;
  display: flex;
  height: 34px;
  position: relative;
  width: 60px;
`;

export const Emojis = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100% max-content;
  padding: 0 6% 4% 8%;

  span {
    flex: 1;
    font-size: 18px;
    user-select: none;
  }
`;

export const Checkbox = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;

export const Slider = styled.div<{ checked: boolean }>`
  background-color: ${({ theme }) => theme.tt_slider};
  border-radius: 50%;
  height: 26px;
  left: ${({ checked }) => (checked ? "26px" : "0px")};
  margin: 4px;
  position: absolute;
  transition: 0.2s;
  width: 26px;
`;
