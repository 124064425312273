import styled from "styled-components";

import Container from "./Container";

/**
 * Section
 */
export default styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  text-align: center;
`;
