import { useState } from "react";

/**
 * useVideoMuted
 */
const useVideoMuted = (): [
  boolean,
  () => void,
  (current: HTMLVideoElement | null) => void
] => {
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const [muted, setMuted] = useState(true);

  const registerVideo = (current: HTMLVideoElement | null) => {
    setVideo(current);
  };

  const toggleMuted = () => {
    if (video) {
      video.muted = !muted;
      setMuted(!muted);
    }
  };

  return [muted, toggleMuted, registerVideo];
};

export default useVideoMuted;
