import styled from "styled-components";
import { Link as _Link } from "gatsby";
import { FaBars } from "react-icons/fa";

import devices from ".config/devices";

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: inherit;
`;

export const Links = styled.li`
  display: none;

  @media ${devices.laptop} {
    display: flex;
  }

  a:last-child {
    padding-right: 0;
  }
`;

export const Link = styled(_Link)`
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  padding-right: 1.6rem;
  user-select: none;
`;

export const Bars = styled(FaBars)`
  color: ${({ theme }) => theme.link};
  cursor: pointer;
  font-size: 1.8rem;

  &:hover {
    color: ${({ theme }) => theme.link_hover};
  }

  @media ${devices.laptop} {
    display: none;
  }
`;
