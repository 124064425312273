// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more — Extra large screens, TV

const size = {
  mobile: 320,
  tablet: 481,
  laptop: 769,
  desktop: 1025,
  tv: 1201,
};

export default {
  mobile: `(min-width: ${size.mobile}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  tv: `(min-width: ${size.tv}px)`,
};
