import React from "react";

import * as Styled from "./styled";
import Title from "./Title";
import Video from "./Video";
import { cover } from ".data/home/intro";

/**
 * Intro
 */
const Intro: React.FC = () => {
  return (
    <Styled.Background image={cover}>
      <Styled.VideoWrapper>
        <Video />
      </Styled.VideoWrapper>
      <Styled.Container>
        <Styled.TitleWrapper>
          <Title />
        </Styled.TitleWrapper>
      </Styled.Container>
    </Styled.Background>
  );
};

export default Intro;
