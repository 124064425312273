import React, { useContext } from "react";

import * as Styled from "./styled";
import { ThemeModeContext } from ".state/context/ThemeManager";

/**
 * ThemeToggler
 */
const ThemeToggler: React.FC = () => {
  const { themeMode, toggleThemeMode } = useContext(ThemeModeContext);

  return (
    <Styled.Switch htmlFor="checkbox">
      <Styled.Emojis>
        <span>🌜</span>
        <span>🌞</span>
      </Styled.Emojis>
      <Styled.Checkbox
        id="checkbox"
        type="checkbox"
        onChange={toggleThemeMode}
      />
      <Styled.Slider checked={themeMode === "dark"} />
    </Styled.Switch>
  );
};

export default ThemeToggler;
