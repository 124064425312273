import { createGlobalStyle } from "styled-components";

/**
 * Global
 */
export default createGlobalStyle`
  :root {
    --border: 1px solid ${({ theme }) => theme.border};
    --dropdown-size: 60px;
    --header-height: 80px;
  }
  
  * {
    font-family: 'Notable', sans-serif;
    letter-spacing: 2px;
    line-height: 1.3;
  }
  
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.link};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.link_hover};
    }
  }

  h1 {
    color: ${({ theme }) => theme.primary};
    font-size: clamp(2.188rem, calc( 12px + 4.125vmin ), 4.813rem);
    margin-bottom: 0.1rem;
  }

  h2 {
    color: ${({ theme }) => theme.primary};
    font-size: clamp(1.625rem, calc( 12px + 3.000vmin ), 3.875rem);
    margin-bottom: 0.1rem;
  }

  h3 {
    color: ${({ theme }) => theme.primary};
    font-size: clamp(1.138rem, calc( 12px + 2.250vmin ), 3.313rem);
    margin-bottom: 0.1rem;
  }

  h4 {
    color: ${({ theme }) => theme.primary};
    font-size: clamp(0.875rem, calc( 12px + 1.500vmin ), 2.750rem);
    margin-bottom: 0.1rem;
  }

  p {
    color: ${({ theme }) => theme.text};
    font-size: clamp(0.725rem, calc( 12px + 0.900vmin ), 2.375rem);
    margin-bottom: 0.1rem;
  }

  span {
    color: ${({ theme }) => theme.text};
    font-size: clamp(0.500rem, calc( 12px + 0.600vmin ), 2.188rem);
    margin-bottom: 0.1rem;
  }
    
  sup {
    color: ${({ theme }) => theme.secondary};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;
