import React from "react";

import * as Styled from "./styled";

/**
 * Title
 */
const Title: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Background>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          viewBox="0, 0, 400, 400"
        >
          <path
            d="M132.353 47.431 C 131.275 47.580,127.745 48.033,124.510 48.439 C 109.617 50.305,85.052 62.295,74.232 72.981 C 26.531 120.090,20.676 257.006,63.781 317.373 C 100.542 368.854,183.546 363.458,271.569 303.866 C 319.586 271.358,363.504 224.040,365.193 202.994 C 367.421 175.240,292.853 104.204,226.797 71.153 C 192.606 54.045,154.256 44.413,132.353 47.431 "
            fillRule="evenodd"
          ></path>
        </svg>
      </Styled.Background>
      <Styled.Text>
        <h1>
          Rambler<sup>5</sup>
        </h1>
        <p>Handmade Music</p>
      </Styled.Text>
    </Styled.Container>
  );
};

export default Title;
