import styled from "styled-components";
import { Link as _Link } from "gatsby";

export const Link = styled(_Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: inherit;
`;

export const Wrapper = styled.div`
  height: 80%;

  svg {
    fill: ${({ theme }) => theme.logo};
  }

  svg:hover {
    fill: ${({ theme }) => theme.logo_hover};
  }
`;
